<template>
  <div class="box">
    <div class="top-box">
      <van-tabs v-model:active="active" @click-tab="onClickTab">
        <van-tab
          v-for="item in tabList"
          :title="item.businessName"
          :name="item.id"
          swipeable
        ></van-tab>
      </van-tabs>
      <van-notice-bar
        left-icon="volume-o"
        scrollable
        :text="text"
        color="#1989FA"
        background="#fff"
      />
    </div>

    <div class="bottom-box">
      <div class="left-box">
        <van-skeleton title :row="5" :loading="cityLoading">
          <van-sidebar v-model="leftActive" @change="onChange">
            <van-sidebar-item
              v-for="item in cityList"
              :title="item.regionName"
              class="custom-sidebar"
            />
          </van-sidebar>
        </van-skeleton>
      </div>
      <div class="right-box">
        <van-skeleton title :row="8" :loading="loading">
          <div v-for="(item, index) in cardList" class="card-box">
            <div class="top-label" v-if="index == 0">置&nbsp;顶</div>
            <div class="title-box">
              <span class="ellipsis">{{ item.name }}</span>
            </div>

            <img
              :src="item.image"
              draggable="false"
              @click="showImg(item.image)"
            />
          </div>
        </van-skeleton>
      </div>
    </div>
    <div> <p style="color: gray;
    font-size: small;">
        
 
          <a href="http://beian.miit.gov.cn/" target="_blank"
            >晋ICP备2023020182号-1</a
          >
        </p></div>
    <!--    <van-loading v-model="loadingVisible" />-->
  </div>
</template>

<script setup>
import { reactive, onMounted, ref } from "vue";
import {
  failed_code,
  success_code,
  getTopInfo,
  getLeftInfo,
  getRightInfo,
  getSign,
} from "../api/api.js";
import { formatDates } from "../utils/date.js";
import { showImagePreview } from "vant";
import { showFailToast } from "vant";

const loadingVisible = ref(false);

const showImg = (img) => {
  showImagePreview({
    images: [img],
    closeable: true,
  });
};

const tabList = ref([]);
const cityList = ref([]);
const cardList = ref([]);
const onClickTab = () => {
  console.log(active.value, "active");
  getLeftList(active.value);
};
const leftActive = ref(0);
const onChange = (index) => {
  console.log(index, cityList.value[index]);
  getRightList(cityList.value[index].id);
};

const weiboHotNews = ref([]);
const loading = ref(false);
const cityLoading = ref(false);
const active = ref(1);
const text = ref(
  "无论我们能活多久，我们能够享受的只有无法分割的此刻，此外别无其他"
);
const minDate = new Date(2023, 6, 29);

async function getTopList() {
  console.log("top");
  await getTopInfo().then((res) => {
    console.log(res, "res");
    if (res.success) {
      tabList.value = res.result;
      if (tabList.value.length > 0) {
        getLeftList(tabList.value[0].id);
      }
    } else {
      showFailToast(res.message);
    }
  });
}
async function getSigns() {
  console.log("top");
  await getSign().then((res) => {
    console.log(res, "res");
    if (res.success) {
      text.value = res.result.text;
    } else {
      text.value = "大家好";
    }
  });
}
async function getLeftList(id) {
  cityLoading.value = true;
  await getLeftInfo(id).then((res) => {
    console.log(res, "left");

    if (res.success) {
      leftActive.value = 0;
      cityList.value = res.result;
      if (cityList.value.length > 0) {
        getRightList(cityList.value[0].id);
      } else {
        cardList.value = [];
      }
    } else {
      cardList.value = [];
      showFailToast(res.message);
    }
  });
  cityLoading.value = false;
}

async function getRightList(city) {
  loading.value = true;
  await getRightInfo(city).then((res) => {
    console.log(res, "right");
    if (res.success) {
      cardList.value = res.result;
    } else {
      showFailToast(res.message);
    }
  });
  loading.value = false;
}

onMounted(() => {
  getTopList();
  getSigns();
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  width: 100vw;
  min-height: 100vh;
  background-color: gainsboro;
  padding: 10px;
  box-sizing: border-box;
}
.top-box {
  height: 10vh;
  padding: 4px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bottom-box {
  height: calc(90vh - 65px);
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.left-box {
  border-radius: 10px;
  width: 24vw;
  background-color: #fff;

  overflow: scroll;
}

.right-box {
  width: 68vw;
  margin-left: 10px;
  overflow: scroll;
  border-radius: 10px;
}
.top-label {
  /* display: inline; */
    /* float: right; */
    background: #D0D0D1;
    padding: 5px;
    color: #fff;
    border-radius: 3px;
    padding-left: 4px;
    padding-right: 4px;
    /* position: absolute; */
    /* z-index: 2; */
    /* left: 0; */
    margin-right: 6px;
    /* top: 0; */
    font-size: 13px;
}
.card-box {
  position: relative;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.card-box:last-child {
  margin-bottom: 0;
}

.card-box img {
  margin-top: 10px;
  width: 96%;
}

.title-box {
  margin-top: 10px;
}

.ellipsis {
  display: inline-block;
  max-width: 246px;
  /* 设置最大宽度 */
  white-space: nowrap;
  /* 防止换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}

.custom-sidebar {
  font-size: 12px;
  width: 100%;
  line-height: 0;
  background-color: #fff;
}

.custom-item:active,
.custom-item:focus {
  color: #1989fa;
  /* 选中时的字体颜色 */
}

:root:root:root {
  --van-sidebar-width: 10px;
  --van-sidebar-font-size: var(--van-font-size-xs);
}
</style>
