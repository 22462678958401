<template>
  <HelloWorld />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */

  /* width: 390px; 假设您希望页面的宽度显示为手机的宽度，比如iPhone 6/7/8的宽度 */
  /* height: 844px; 假设您希望页面的高度显示为手机的高度，比如iPhone 6/7/8的高度 */
  margin: 0 auto; /* 这会使.container在页面中水平居中 */

}
html, body {
  overscroll-behavior-y: none;
}
@media (max-width: 768px) {
  #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw; /* 视口宽度的 100% */
  height: 100vh; /* 视口高度的 100% */
  overscroll-behavior-y: none;

}
html, body {
  overscroll-behavior-y: none;
}
}
</style>
