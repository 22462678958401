import http from './http';

export const success_code = 20000;
export const failed_code = 40000;


//登录
export const getTopInfo = () => {
    return http.requestGet('/jeecg-boot/share/business/selectList');
};
export const getLeftInfo = (id) => {
  return http.requestGet('/jeecg-boot/share/cregion/selectList?businessId='+id);
};
export const getRightInfo = (id) => {
  return http.requestGet('/jeecg-boot/share/cregionInfo/selectList?cregionId='+id);
};
export const getSign = () => {
  return http.requestGet('/jeecg-boot/share/advertisement/get');
};
// //登录
// export const addInfo = (vo) => {
//     return http.requestPost('/portal/comment/addInfo',vo);
//   };
  
//   //登录
//   export const addOperationLog = (vo) => {
//     return http.requestPost('/portal/comment/addOperationLog',vo);
//   };
